<template>
    <div class="clientcabin clientcabin-about">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="px-6 px-md-3">
                    <p class="text-h3 text-center py-md-12">About us</p>
                    <div
                        v-if="options?.reseller_profile_image"
                        class="reseller_image"
                        :class="{ 'float-right': $vuetify.breakpoint.mdAndUp }"
                    >
                        <v-img
                            :src="options?.reseller_profile_image"
                            :alt="
                                options?.business_name +
                                ' reseller profile image'
                            "
                            class="rounded-lg"
                        />
                    </div>
                    <div
                        v-else
                        class="reseller_image empty"
                        :class="{ 'float-right': $vuetify.breakpoint.mdAndUp }"
                    ></div>
                    <div class="about black--text">
                        <p>Hi, I'm {{ me }}!</p>
                        <p>
                            Here at {{ company }}, we’re not just another
                            digital agency. We’re a team of diverse specialists
                            - from content maestros to developers and
                            advertising professionals.
                        </p>
                        <p>
                            We believe in the power of organic traffic growth as
                            it has given various businesses we’ve worked with
                            incredible results. These aren’t quick fixes or
                            temporary solutions. Instead, we’re here to help you
                            build a sustainable, thriving online presence that
                            continues to grow with unique exposure campaigns.
                        </p>
                        <p class="text-h3 text-center py-md-12">What We Do</p>
                        <p>
                            At the heart of our service is our proprietary
                            Create, Repurpose, and Distribute model. This
                            approach to growing your organic traffic is as
                            effective as it is innovative.
                        </p>
                        <p>
                            <b>Create:</b>
                            We’ll dive deep into your business, understanding
                            your unique value proposition and the questions your
                            potential customers are asking. From this insight,
                            we craft content that resonates with your audience
                            and aligns with their search intent.
                        </p>
                        <p>
                            <b>Repurpose:</b>
                            This is where the magic happens. We take that same
                            content and transform it into a variety of formats -
                            articles, videos, infographics, audio ads, blog
                            posts, and slide shows. This multi-format approach
                            ensures your message reaches audiences across
                            different platforms and their content consumption
                            preferences.
                        </p>
                        <p>
                            <b>Distribute:</b>
                            This is our secret sauce. We leverage our extensive
                            network (300+) of high authority sites, news
                            outlets, and niche platforms to publish your content
                            everywhere online. This distribution reverberates
                            across the web - creating visibility for your
                            business that Google simply can’t ignore.
                        </p>
                        <p>
                            We specialize in helping you grow your organic
                            traffic completely done for you, so you are able to
                            connect with the people actually looking and
                            researching and ready for your solution. Our
                            approach is scalable and adaptable. We don't just
                            work for you – we work with you, becoming an
                            extension of your team, aligned with your organic
                            traffic goals and committed to your success.
                        </p>
                        <p>
                            With {{ company }}, you're not just getting a
                            service – you're gaining a partner in your journey
                            to truly done-for-you organic traffic.
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="px-6 px-md-3">
                        <p class="text-h4 text-center pt-md-10 pb-md-6">
                            Ready For Done-For-You-Organic Traffic?
                            <br />
                            You’ll Love Working With Us!
                        </p>
                        <p class="text-center">
                            <v-btn
                                v-if="options?.reseller_booking_url"
                                :large="$vuetify.breakpoint.mdAndUp"
                                class="primary mr-4"
                                to="/book"
                            >
                                Book My Appointment
                            </v-btn>
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container fluid class="testimonials clientcabin-darkblue">
            <v-row justify="center">
                <v-col>
                    <v-container class="position--relative">
                        <v-row justify="center">
                            <v-col
                                cols="12"
                                lg="9"
                                xl="7"
                                class="pt-16 pb-md-16 d-flex justify-center"
                            >
                                <customer-testimonials />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { InjectReactive } from '@/utils/decorators';
import { mapGetters } from 'vuex';

import { CustomerTestimonials } from '@/components/ClientCabin/CustomerTestimonials';

import type { ClientCabin } from '@/types/ClientCabin';

@Component({
    name: 'About',
    components: {
        CustomerTestimonials
    },
    computed: {
        ...mapGetters('logo', ['logo'])
    }
})
export default class About extends Vue {
    logo!: { src: string; alt: string };

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get me() {
        return this.options?.reseller_name ?? 'the Company Owner';
    }

    get company() {
        return this.options?.business_name ?? 'Our Company';
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-about::v-deep {
    background-color: $white;
    color: $black;

    .reseller_image {
        width: 270px;
        height: 270px;

        display: block;
        margin: 0 auto 1em;
        overflow: hidden;

        &.empty {
            border-radius: 50%;
            background: linear-gradient(
                -45deg,
                #3060bd,
                #aaebff 50%,
                white 49%,
                white 51%,
                #3060bd 51%
            );
        }
    }

    .about {
        font-size: 110%;

        p {
            padding-bottom: 1em;
        }
    }
}
</style>
